import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Tooltip as MuiTooltip } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useLocalStorage } from '../utils/localStorage.js';

import { theme } from '../utils/theme.jsx';

function valToString(val, digits) {
  return val.toLocaleString('default', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

const employerCostMultiplier = 0.3142; // Arbetsgivaravgifter
const itpThreshold = 46438.0;
const itpLow = 0.045;
const itpHigh = 0.3;
const workingDaysPerYear = 251.0;
const workingHoursPerDay = 8.0;

function EmployeePensionCost(salary) {
  let pensionCost = Math.min(salary, itpThreshold) * itpLow;
  pensionCost += salary > itpThreshold ? (salary - itpThreshold) * itpHigh : 0.0;

  return pensionCost;
}

function EmployeePayrollTax(salary) {
  return salary * employerCostMultiplier;
}

function EmployeeMISR(monthlyIncome, monthlyExpenses) {
  return monthlyIncome > 0 ? monthlyExpenses / monthlyIncome : 0.0;
}

function EmployeeBASR(monthlyExpenses, balance) {
  return monthlyExpenses > 0 ? balance / monthlyExpenses : 0.0;
}

export function EmployeeSalaryBalanceData(employee, transactions) {
  let balance = 0.0;
  if (transactions) {
    for (let i = 0; i < transactions.length; ++i) {
      if (transactions[i].account === '7219') {
        continue;
      }
      balance += transactions[i].amount;
    }
  }

  let approxMonthlyExpenses = employee.salary;

  approxMonthlyExpenses += EmployeePayrollTax(employee.salary);
  approxMonthlyExpenses += EmployeePensionCost(employee.salary);

  const hourlyIncome = employee.assignments.reduce((a, b) => {
    return a + b.hourlyrateuser / employee.assignments.length;
  }, 0.0);

  const workDaysPerMo = workingDaysPerYear / 12;
  const monthlyIncome = workDaysPerMo * workingHoursPerDay * hourlyIncome;

  const accountBalanceSafetyRatio = EmployeeBASR(approxMonthlyExpenses, balance);
  const salarySafetyRatio = EmployeeMISR(monthlyIncome, approxMonthlyExpenses);

  return [balance, accountBalanceSafetyRatio, salarySafetyRatio];
}

function formatNumber(value, decimals) {
  return typeof value === 'number' && !isNaN(value) ? value.toFixed(decimals) : value;
}

function tooltipBASR(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Balance-Account-to-Salary-Ratio (BASR)</h3>
          <p>
            BASR are your monthly expenses* divided by your current account balance that gives you a rough estimation of
            the number of months your current account balance covers. For example, a BASR of 4.0 should cover your
            monthly expenses* for four months without any additional income.
          </p>
          <br />
          <p>(*) monthly expenses in the BASR calculation includes salary, payroll tax, and pension</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

function tooltipMISR(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Monthly-Income-to-Salary-Ratio (MISR)</h3>
          <p>
            MISR is your monthly income divided by your monthly expenses* that gives you a rough estimation of how your
            balance account will grow, or shrink if MISR &gt; 1, over time. The MISR calculation is using hourly rate
            multipled with the number of work hours per month.
          </p>
          <br />
          <p>(*) monthly expenses in the MISR calculation includes salary, payroll tax, and pension</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

function tooltipFixedExpenses(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Fixed expenses</h3>
          <p>Fixed reoccuring expenses that e.g. company car.</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

export function EmployeeTable(employee, transactions) {
  const hashfunc = function (s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  const [balance, accountBalanceSafetyRatio, salarySafetyRatio] = EmployeeSalaryBalanceData(employee, transactions);

  const hash = hashfunc(employee.email);

  const [ldSalary, setldSalary] = useLocalStorage(`${hash}.meLdSalary`, employee.salary);
  const [ldPension, setldPension] = useLocalStorage(`${hash}.meLdPension`, EmployeePensionCost(employee.salary));
  const [ldFixed, setldFixed] = useLocalStorage(`${hash}.meLdFixed`, 0);
  const [ldNonWorkingDays, setldNonWorkingDays] = useLocalStorage(`${hash}.meLdNonWorkingDays`, 25);
  const [ldHourlyRate, setldHourlyRate] = useLocalStorage(
    `${hash}.meLdHourlyRate`,
    employee.assignments.length ? employee.assignments[employee.assignments.length - 1].hourlyrate : 0
  );
  const [ldWorkingHours, setldWorkingHours] = useLocalStorage(`${hash}.meLdWorkingHours`, workingHoursPerDay);

  const setSalary = (salary) => {
    setldSalary(salary);
    salary = Number(salary);
    if (!isNaN(salary)) {
      setldPension(EmployeePensionCost(salary));
    }
  };

  const totalIncome = () => {
    return ((workingDaysPerYear - Number(ldNonWorkingDays)) / 12) * Number(ldWorkingHours) * Number(ldHourlyRate) * 0.8;
  };

  const totalExpenses = () => {
    return Number(ldSalary) + EmployeePayrollTax(Number(ldSalary)) + Number(ldPension) + Number(ldFixed);
  };

  const tooltipPension = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly pension</h3>
            <p>Monthly pension expenses where the default value follows the ITP1 plan. The value can be overwritten.</p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  const tooltipIncome = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly income</h3>
            <p>
              <b>Calculation:</b>
            </p>
            <table>
              <tr>
                <td>workingDaysPerYear:</td>
                <tr>{workingDaysPerYear}</tr>
              </tr>
              <tr>
                <td>nonWorkingDaysPerYear:</td>
                <tr>{ldNonWorkingDays}</tr>
              </tr>
              <tr>
                <td>workHoursPerDay:</td>
                <tr>{ldWorkingHours}</tr>
              </tr>
              <tr>
                <td>hourlyRate:</td>
                <tr>{ldHourlyRate}</tr>
              </tr>
            </table>
            <br />
            <p>
              monthlyIncome = (workingDaysPerYear - nonWorkingDaysPerYear) / 12 * workHoursPerDay * hourlyRate * 80%
            </p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  const tooltipExpenses = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly expense</h3>
            <p>
              <b>Calculation:</b>
            </p>
            <table>
              <tr>
                <td>salary:</td>
                <tr>{ldSalary}</tr>
              </tr>
              <tr>
                <td>payrollTax:</td>
                <tr>{(employerCostMultiplier * 100).toFixed(2)}%</tr>
              </tr>
              <tr>
                <td>pension:</td>
                <tr>{formatNumber(ldPension, 0)}</tr>
              </tr>
              <tr>
                <td>fixedExpenses:</td>
                <tr>{ldFixed}</tr>
              </tr>
            </table>
            <br />
            <p>monthlyExpense = salary * (1 + payrollTax) + pension + fixedExpenses</p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  let assignments = '';
  for (const assignment of employee.assignments) {
    if (assignments.length) {
      assignments = assignments.concat('\n');
    }
    assignments = assignments.concat(`${assignment.name}\n  Hourly rate: ${assignment.hourlyrate}`);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={4}>
            <Box mx={1} my={1} gap={4} p={1} sx={{ border: '2px solid grey' }}>
              <h3>Employee information</h3>
              <br />
              <TextField
                label="Name"
                value={employee.name}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Email"
                value={employee.email}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Phone"
                value={employee?.phone || 'unknown'}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Address"
                value={employee.address}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Post code"
                value={employee.postcode}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="City"
                value={employee.city}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Country"
                value={employee.country}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Employed since"
                value={employee.employmentdate}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Salary"
                value={valToString(employee.salary, 0)}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Balance account"
                value={valToString(balance, 0)}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              {tooltipBASR(
                <TextField
                  label="Balance Account to Salary ratio"
                  value={accountBalanceSafetyRatio.toFixed(2)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
              {tooltipMISR(
                <TextField
                  label="Monthly Income to Salary ratio"
                  value={salarySafetyRatio.toFixed(2)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
              <TextField
                label="Assignments"
                value={assignments || 'no assignment'}
                variant="standard"
                size="small"
                multiline
                fullWidth
                rows={2 * employee?.assignments.length}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box mx={1} p={1} gap={4} my={1} sx={{ border: '2px solid grey' }}>
              <h3>Employee salary calculation</h3>
              <br />
              <h4>Input parameters</h4>
              <TextField
                label="Salary"
                value={ldSalary}
                variant="standard"
                size="small"
                helperText={isNaN(ldSalary) ? 'Incorrect entry.' : ''}
                onChange={(event) => {
                  setSalary(event.target.value);
                }}
              />
              <TextField
                label="Hourly rate"
                value={formatNumber(ldHourlyRate, 0)}
                variant="standard"
                size="small"
                helperText={isNaN(ldHourlyRate) ? 'Incorrect entry.' : ''}
                onChange={(event) => {
                  setldHourlyRate(event.target.value);
                }}
              />
              {tooltipPension(
                <TextField
                  label="Pension"
                  value={formatNumber(ldPension, 0)}
                  variant="standard"
                  size="small"
                  helperText={isNaN(ldPension) ? 'Incorrect entry.' : ''}
                  onChange={(event) => {
                    setldPension(event.target.value);
                  }}
                />
              )}
              {tooltipFixedExpenses(
                <TextField
                  label="Fixed expenses"
                  value={formatNumber(ldFixed, 0)}
                  variant="standard"
                  size="small"
                  helperText={isNaN(ldFixed) ? 'Incorrect entry.' : ''}
                  onChange={(event) => {
                    setldFixed(event.target.value);
                  }}
                />
              )}
              <TextField
                label="Non working days"
                value={formatNumber(ldNonWorkingDays, 0)}
                variant="standard"
                size="small"
                helperText={isNaN(ldNonWorkingDays) ? 'Incorrect entry.' : ''}
                onChange={(event) => {
                  setldNonWorkingDays(event.target.value);
                }}
              />
              <TextField
                label="Work hours per day"
                value={formatNumber(ldWorkingHours, 0)}
                variant="standard"
                size="small"
                helperText={isNaN(ldWorkingHours) ? 'Incorrect entry.' : ''}
                onChange={(event) => {
                  setldWorkingHours(event.target.value);
                }}
              />
              <br />
              <br />
              <h4>Calculation result</h4>
              {tooltipIncome(
                <TextField
                  label="Income"
                  value={valToString(totalIncome(), 0)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
              {tooltipExpenses(
                <TextField
                  label="Expenses"
                  value={valToString(totalExpenses(), 0)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
              <TextField
                label="Diff"
                value={valToString(totalIncome() - totalExpenses(), 0)}
                variant="standard"
                size="small"
                InputProps={{ readOnly: true }}
              />
              {tooltipMISR(
                <TextField
                  label="MISR"
                  value={formatNumber(EmployeeMISR(totalIncome(), totalExpenses()), 2)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
              {tooltipBASR(
                <TextField
                  label="BASR"
                  value={formatNumber(EmployeeBASR(totalExpenses(), balance), 2)}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: true }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
