import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import { Home } from './pages/Home.jsx';
import { LoginLayout, LoginAuthOk, loginAuthOkLoader, LogoutLayout } from './pages/Auth.jsx';
import { Me, meLoader } from './pages/Me.jsx';
import { AdminLayout, adminLoader } from './pages/Admin.jsx';
import { AnalyticsLayout, analyticsLoader } from './pages/Analytics.jsx';
import { BalanceAccount, balanceAccountLoader } from './pages/BalanceAccount.jsx';
import { ExpenseReports, expenseReportsLoader } from './pages/ExpenseReports.jsx';
import { Employees, employeesLoader } from './pages/Employees.jsx';
import { CostCenters, costCentersLoader } from './pages/CostCenters.jsx';
import { CostCenter } from './pages/CostCenter.jsx';
import { Employee } from './pages/Employee.jsx';

import ErrorPage from './pages/ErrorPage.jsx';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';

import './style.scss';

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/me',
        element: <Me />,
        loader: meLoader,
      },
      {
        path: '/balance-account',
        element: <BalanceAccount />,
        loader: balanceAccountLoader,
      },
      {
        path: '/expense-reports',
        element: <ExpenseReports />,
        loader: expenseReportsLoader,
      },
      {
        path: '/cost-centers',
        children: [
          {
            index: true,
            element: <CostCenters />,
            loader: costCentersLoader,
          },
          {
            path: ':id',
            element: <CostCenter />,
          },
        ],
      },
      {
        path: '/employees',
        children: [
          {
            index: true,
            element: <Employees />,
            loader: employeesLoader,
          },
          {
            path: ':id',
            element: <Employee />,
          },
        ],
      },
      {
        path: '/admin',
        element: <AdminLayout />,
        loader: adminLoader,
      },
      {
        path: '/analytics',
        element: <AnalyticsLayout />,
        loader: analyticsLoader,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginLayout />,
  },
  {
    path: '/sdaklfjsadflsajdfklsadfjsaldkfjsadlfjsdalfkjaldfksafweyhowancaoshdgsa',
    element: <LoginAuthOk />,
    loader: loginAuthOkLoader,
  },
  {
    path: '/logout',
    element: <LogoutLayout />,
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
