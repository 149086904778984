import { useLoaderData, redirect, Link } from 'react-router-dom';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import { useLocalStorage } from '../utils/localStorage.js';
import { theme } from '../utils/theme.jsx';

import { CostCentersData, EmployeesData } from '../components/user.jsx';
import { EmployeeSalaryBalanceData } from '../components/Employee.jsx';

function findCostCenterTransactions(key, costCenters) {
  const costCenter = costCenters.find((cc) => cc.code === key);

  return costCenter ? costCenter.transactions : null;
}

function valToString(val) {
  val = val || 0;
  return val.toLocaleString('default', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function descendingComparator(a, b, orderBy) {
  const aval = a[orderBy];
  const bval = b[orderBy];

  return aval === undefined || aval < bval ? -1 : bval === undefined || aval > bval ? 1 : 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'company',
    align: 'left',
    disablePadding: false,
    label: 'Company',
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'costcenter',
    align: 'left',
    disablePadding: false,
    label: 'Cost-center',
  },
  {
    id: 'assignments',
    align: 'left',
    disablePadding: false,
    label: 'Assignments',
  },
  {
    id: 'employmentdate',
    align: 'left',
    disablePadding: false,
    label: 'Employed',
  },
  {
    id: 'balance',
    align: 'right',
    disablePadding: false,
    label: 'Balance Account',
  },
  {
    id: 'accountBalanceSafetyRatio',
    align: 'right',
    disablePadding: false,
    label: 'BASR',
  },
  {
    id: 'salarySafetyRatio',
    align: 'right',
    disablePadding: false,
    label: 'MISR',
  },
  {
    id: 'lastlogin',
    align: 'right',
    disablePadding: false,
    label: 'Last Login',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className="table-header"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export function Employees() {
  const [companyCostCenters, companyEmployees] = useLoaderData();

  const companies_ = [];
  const companies__ = [];
  for (const company of Object.keys(companyEmployees)) {
    const numEmployees = companyEmployees[company].length;
    if (numEmployees) {
      companies_.push({ name: company, numEmployees });
      companies__.push(company);
    }
  }

  const [order, setOrder] = useLocalStorage('empOrder', 'asc');
  const [orderBy, setOrderBy] = useLocalStorage('empOrderBy', 'employmentdate');
  const [page, setPage] = useLocalStorage('empPage', 0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage('empRowsPerPage', 25);
  const [companies, setCompanies] = useLocalStorage('empCompanies', companies__);

  const es = [];
  for (const company of companies) {
    for (const employee of companyEmployees[company]) {
      const transactions = findCostCenterTransactions(employee.costcenter, companyCostCenters[company]);
      const [balance, accountBalanceSafetyRatio, salarySafetyRatio] = transactions
        ? EmployeeSalaryBalanceData(employee, transactions)
        : [0, 0, 0];
      employee.balance = balance;
      employee.accountBalanceSafetyRatio = accountBalanceSafetyRatio;
      employee.salarySafetyRatio = salarySafetyRatio;
      employee.company = company;
      employee.id = uuidv4();
      es.push(employee);
    }
  }
  const [employees, setEmployees] = React.useState(() => es);

  const handleDevices = (event, newCompanies) => {
    setCompanies(newCompanies);

    const employees_ = [];
    for (const company of Object.keys(companyEmployees)) {
      for (const employee of companyEmployees[company]) {
        if (!newCompanies.includes(company)) {
          continue;
        }
        const transactions = findCostCenterTransactions(employee.costcenter, companyCostCenters[company]);
        const [balance, accountBalanceSafetyRatio, salarySafetyRatio] = transactions
          ? EmployeeSalaryBalanceData(employee, transactions)
          : [0, 0, 0];
        employee.balance = balance;
        employee.accountBalanceSafetyRatio = accountBalanceSafetyRatio;
        employee.salarySafetyRatio = salarySafetyRatio;
        employee.company = company;
        employee.id = uuidv4();
        employees_.push(employee);
      }
    }

    setEmployees(employees_);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employees.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(employees, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, employees]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <ThemeProvider theme={theme}>
          <TableContainer>
            <ToggleButtonGroup value={companies} onChange={handleDevices} color="primary">
              {companies_.map((company) => (
                <ToggleButton key={company.name} value={company.name}>
                  {company.name + ' (' + company.numEmployees + ')'}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Table sx={{ minWidth: 750 }} size="small">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((e) => {
                  return (
                    <TableRow hover key={e.id} style={!e.sunshineaccess ? { backgroundColor: red[100] } : {}}>
                      <TableCell component="th" scope="row">
                        {e.company}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={e.email}
                          state={{ data: [e, findCostCenterTransactions(e.costcenter, companyCostCenters[e.company])] }}
                        >
                          {e.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/cost-centers/${e.costcenter}`}
                          state={{ data: companyCostCenters[e.company].find((cc) => cc.code === e.costcenter) }}
                        >
                          {e.costcenter}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {e.assignments.map((assignment) => (
                          <p key={assignment.id}>
                            {assignment.name} -{' '}
                            <i>
                              Hourly rate: {assignment.hourlyrate} / {assignment.hourlyrateuser} -{' '}
                              {((assignment.hourlyrateuser / assignment.hourlyrate) * 100).toFixed(2)}%
                            </i>
                          </p>
                        ))}
                      </TableCell>
                      <TableCell>{e.employmentdate}</TableCell>
                      <TableCell align="right">{valToString(e.balance)}</TableCell>
                      <TableCell align="right">{e.accountBalanceSafetyRatio.toFixed(2)}</TableCell>
                      <TableCell align="right">{e.salarySafetyRatio.toFixed(2)}</TableCell>
                      <TableCell align="right">
                        {e.lastlogin ? new Date(e.lastlogin).toLocaleString('sv-SE', { dateStyle: 'short' }) : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={employees.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </Paper>
    </Box>
  );
}

export const employeesLoader = async () => {
  let costCenters = null;
  await CostCentersData().then(function (data) {
    costCenters = data;
  });

  let employees = null;
  await EmployeesData().then(function (data) {
    employees = data;
  });

  return costCenters && employees ? [costCenters, employees] : redirect('/login');
};
