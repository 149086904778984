import React from 'react';
import { useLoaderData, redirect } from 'react-router-dom';

import { TransactionsGraph, TransactionsTable } from '../components/Transactions.jsx';
import { BalanceAccountData, LastLogin } from '../components/user.jsx';

export function BalanceAccount() {
  const transactions = useLoaderData();
  const lastLogin = LastLogin();

  return (
    <>
      <div>{TransactionsGraph(transactions, false)}</div>
      <div>{TransactionsTable(transactions, lastLogin, false)}</div>
    </>
  );
}

export const balanceAccountLoader = async () => {
  let transactions = null;
  await BalanceAccountData().then(function (data) {
    transactions = data;
  });

  return transactions || redirect('/login');
};
