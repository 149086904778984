import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
  },
  root: {
    width: '0.5rem',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          color: 'black',
          backgroundColor: 'lightgray',
          maxWidth: 400,
        },
      },
    },
  },
});

const companyColorMap = {
  embedded: '#7FC7D9',
  swflow: '#B7E5B4', // good
  electro: '#F28585',
  application: '#7977A1',
  connectivity: '#43766C',
};

export function companyToColor(company) {
  const c = company.toLowerCase().replace('-', '');

  return c in companyColorMap ? companyColorMap[c] : '#000000';
}
